import {
  APP_BASE_HREF,
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipe,
} from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideFirebase } from '@core/firebase';
import {
  provideErrorHandler,
  provideHttp,
  provideSnackbar,
} from '@core/services';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    DatePipe,
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd/MM/yyyy' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttp(),
    provideSnackbar(),
    provideErrorHandler(),
    provideFirebase(),
  ],
};
