<mat-card class="sign-in__card">
  <div class="sign-in__info">
    <img
      width="98"
      height="98"
      src="assets/images/logo.svg"
      alt="Remedee Logo"
    />
    <div class="sign-in__info__app-name custom-font-login-1">Report Engine</div>
    <div class="sign-in__info__app-version custom-font-login-2">
      Version {{ version }}
    </div>
    <div class="sign-in__info__app-env custom-font-login-3">
      {{ environment }}
    </div>
  </div>

  <!-- Calling GoogleAuth Api from AuthService -->
  <div class="sign-in__button-container">
    <button
      data-test="login"
      type="button"
      mat-raised-button
      (click)="googleAuth()"
    >
      <!-- TODO: use a mat-angular icon maybe -->
      <i class="fab fa-google-plus-g"></i>
      Log in with Google
    </button>
  </div>
</mat-card>
