import { FirebaseError } from '@angular/fire/app';

export function isFirebaseError(error: any): error is FirebaseError {
  const name = error?.name;
  const code = error?.code;
  return name === 'FirebaseError' && typeof code === 'string';
}

export function isFirebaseUnavailableError(error: any): error is FirebaseError {
  return isFirebaseError(error) && error.code === 'unavailable';
}
