import { FirebaseError } from 'firebase/app';
import { isFirebaseError } from '../error-utils';

export function isAnalyticsError(error: any): error is FirebaseError {
  const code = error?.code;
  return (
    isFirebaseError(error) &&
    typeof code === 'string' &&
    code.startsWith('auth/')
  );
}
