import { ErrorHandler, InjectionToken, Provider } from '@angular/core';
import { ErrorHandlerMapper } from './error-handler.models';
import { ErrorHandlerService } from './error-handler.service';
import { AngularHttpErrorMapper } from './mappers/handle-angular-http-error';
import { FirebaseUnavailableMapper } from './mappers/handle-firebase-unavailable';
import { AnalyticsCookiesNotEnabledMapper } from './mappers/ignore-analytics-cookies-not-enabled';

export const ERROR_HANDLER_MAPPER = new InjectionToken<ErrorHandlerMapper[]>(
  'ErrorHandlerMapper'
);

export function provideErrorHandler(): Provider[] {
  return [
    ErrorHandlerService,
    { provide: ErrorHandler, useExisting: ErrorHandlerService },
    {
      provide: ERROR_HANDLER_MAPPER,
      useClass: AngularHttpErrorMapper,
      multi: true,
    },
    {
      provide: ERROR_HANDLER_MAPPER,
      useClass: FirebaseUnavailableMapper,
      multi: true,
    },
    {
      provide: ERROR_HANDLER_MAPPER,
      useClass: AnalyticsCookiesNotEnabledMapper,
      multi: true,
    },
  ];
}
